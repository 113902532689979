import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import { StaticImage } from 'gatsby-plugin-image'
import Gras from '../P/Gras'

const HeadingH1 = styled.h1`
  text-align: center;
  font-style: normal;
  font-family: 'I AM A PLAYER';
  font-weight: normal;
  font-style: normal;
  font-weight: normal;
  font-weight: bold;
  padding: 3vh 2vw 1vh 2vw;
  margin: 0;
  color: #e6332a;
  background-color: transparent;
  text-decoration: none;
  font-size: 1.7rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`
const HeadingPara = styled.div`
  font-size: 10pt;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  background-color: transparent;
  text-decoration: none;
`
const WrapGuepe = styled.div`
  display: block;
  margin: auto;

  @media (max-width: 768px) {
    display: none;
  }
`
const Heading = () => (
  <Flex flexWrap="wrap" bg="#42e2b8">
    <WrapGuepe>
      <Box width={[1, 1 / 3]} pt={[3, 3, 4]}>
        <StaticImage
          src="../../images/logo.png"
          alt="A Logo"
          placeholder="blurred"
          layout="fixed"
          width={200}
        />
      </Box>
    </WrapGuepe>
    <Box width={[1, 1 / 3]} pt={[3, 3, 4]}>
      <HeadingH1>
        ABC Guêpes : Le spécialiste de la destruction de nids de guêpes et de frelons dans le 77 et
        départements alentours.
      </HeadingH1>
    </Box>
    <WrapGuepe>
      <Box width={[1, 1 / 3]} pt={[3, 3, 4]}>
        <StaticImage
          src="../../images/logo.png"
          alt="A Logo"
          placeholder="blurred"
          layout="fixed"
          width={200}
        />
      </Box>
    </WrapGuepe>
    <Box width={[1, 1]} p={[3, 3, 4]}>
      <HeadingPara>
        Nous sommes une
        <Gras> entreprise professionnelle </Gras>
        et
        <Gras> spécialisée </Gras>
        dans la
        <Gras> désinsectisation </Gras>
        et la
        <Gras> destruction </Gras>
        des
        <Gras>nids d’hyménoptères </Gras>
        tels que les
        <Gras> abeilles </Gras>
        , les
        <Gras> guêpes </Gras>
        , les
        <Gras> frelons </Gras>
        et les
        <Gras> frelons asiatiques </Gras>
        .
        <br />
        Nous intervenons en Seine et Marne et les alentours tels que l’Ile de France, l'Essonne, le
        Val de Marne, la Seine Saint Denis, l’Yonne ou encore le Loiret 24H/24 7j7 et même les jours
        fériés.
      </HeadingPara>
    </Box>
  </Flex>
)

export default Heading
